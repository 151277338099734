var fn = {
  // 获取当前时间
  getNowTime() {
    var el = new Date()
    var y = el.getFullYear(); //获取完整的年份(4位,1970-????)
    var m = el.getMonth() + 1; //获取当前月份(0-11,0代表1月)
    var d = el.getDate(); //获取当前日(1-31)
    if (m < 10) {
      m = "0" + m;
    }
    if (d < 10) {
      d = "0" + d;
    }
    return y + "-" + m + "-" + d;
  },
}
export default fn