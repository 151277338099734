<!--  -->
<template>
    <div class="pd200" style="min-height: 100vh;display: flex;align-items: center;justify-content: center;">
        <!-- <div class="title-auth">操作指南</div> -->
        <div>
            <div v-if="count == 1" style="position: relative;">
                <div class="step-box">
                    <div class="step-num">1</div>
                    <div class="step-title">注册</div>
                    <div class="yanshi" @click="godemoIndex">项目演示</div>
                </div>
                <div class="item-auth">
                    （1）登录潍坊政务服务网网址：http://wfzwfw.sd.gov.cn/wf/public/index<br />
                    <span class="red">注意：</span>若没有账号，需先注册账号，再登录
                </div>
                <img class="auth-img" src="@/assets/images/auth1.png" alt="">
                <div class="tips-img">▲采用 <span class="str">法人登录</span> 的方式登录潍坊政务服务网</div>
            </div>
            <div v-if="count == 2">
                <div class="step-box">
                    <div class="step-num">2</div>
                    <div class="step-title">登录</div>
                </div>
                <div class="item-auth">
                    （1）注册完成以后点击右边统一电子印章公共服务平台（或者将网页拉至底部，找到统一电子印章公共服务平台入口）
                </div>
                <img class="auth-img" src="@/assets/images/auth2.png" alt="">
                <div class="tips-img">
                    ▲注册完成→点击 <span class="str">统一电子印章公共服务平台</span>
                </div>
            </div>
            <div v-if="count == 3">
                <div class="step-box">
                    <div class="step-num">2</div>
                    <div class="step-title">登录</div>
                </div>
                <div class="item-auth">
                    （1）注册完成以后点击右边统一电子印章公共服务平台（或者将网页拉至底部，找到统一电子印章公共服务平台入口）
                </div>
                <img class="auth-img" src="@/assets/images/auth3.png" alt="">
                <div class="tips-img">
                    ▲网页底部→点击 <span class="str">统一电子印章公共服务平台</span>
                </div>
            </div>
            <div v-if="count == 4">
                <div class="step-box">
                    <div class="step-num">2</div>
                    <div class="step-title">登录</div>
                </div>
                <div class="item-auth">
                    （2）点击统一电子印章公共服务平台进入电子印章平台登陆界面，点击右上角登陆按钮，输入账号、密码、验证码之后点击登陆，即可完成。
                </div>
                <img class="auth-img" src="@/assets/images/auth4.png" alt="">
                <div class="tips-img">
                    ▲点击统一电子印章公共服务平台法 <span class="str">右上角登陆</span>
                </div>
            </div>
            <div v-if="count == 5">
                <div class="step-box">
                    <div class="step-num">2</div>
                    <div class="step-title">登录</div>
                </div>
                <div class="item-auth">
                    （2）点击统一电子印章公共服务平台进入电子印章平台登陆界面，点击右上角登陆按钮，输入账号、密码、验证码之后点击登陆，即可完成。
                </div>
                <img class="auth-img" src="@/assets/images/auth5.png" alt="">
                <div class="tips-img">
                    ▲输入账号、密码、验证码→点击登陆
                </div>
            </div>
            <div v-if="count == 6">
                <div class="step-box">
                    <div class="step-num">3</div>
                    <div class="step-title">增加授权人企业账户</div>
                </div>
                <img class="auth-img" src="@/assets/images/06.jpg" alt="">
                <div class="tips-img">
                    ▲企业账号登录山东政务服务网进入单位空间
                </div>
            </div>
            <div v-if="count == 7">
                <div class="step-box">
                    <div class="step-num">3</div>
                    <div class="step-title">增加授权人企业账户</div>
                </div>
                <img class="auth-img" src="@/assets/images/07.jpg" alt="">
                <div class="tips-img">
                    ▲添加单位用户
                </div>
            </div>
            <div v-if="count == 8">
                <div class="step-box">
                    <div class="step-num">3</div>
                    <div class="step-title">增加授权人企业账户</div>
                </div>
                <img class="auth-img" src="@/assets/images/08.jpg" alt="">
                <div class="tips-img">
                    ▲填写被授权人账号信息
                </div>
            </div>
            <div v-if="count == 9">
                <div class="step-box">
                    <div class="step-num">4</div>
                    <div class="step-title">领取</div>
                </div>
                <img class="auth-img" src="@/assets/images/auth6.png" alt="">
                <div class="tips-img">
                    ▲登陆进入系统页面找到印章服务
                </div>
            </div>
            <div v-if="count == 10">
                <div class="step-box">
                    <div class="step-num">4</div>
                    <div class="step-title">领取</div>
                </div>
                <img class="auth-img" src="@/assets/images/auth7.png" alt="">
                <div class="tips-img">▲点击右边蓝色领取印章</div>
            </div>
            <div v-if="count == 11">
                <div class="step-box">
                    <div class="step-num">4</div>
                    <div class="step-title">领取</div>
                </div>
                <img class="auth-img" src="@/assets/images/auth8.png" alt="">
                <div class="tips-img">
                    ▲领取印章
                </div>
            </div>
            <div v-if="count == 12">
                <div class="step-box">
                    <div class="step-num">4</div>
                    <div class="step-title">领取</div>
                </div>
                <img class="auth-img" src="@/assets/images/auth9.png" alt="">
                <div class="tips-img">
                    ▲点击立即领取
                </div>
            </div>
            <div v-if="count == 13">
                <div class="step-box">
                    <div class="step-num">4</div>
                    <div class="step-title">领取</div>
                </div>
                <img class="auth-img" src="@/assets/images/auth10.png" alt="">
                <div class="tips-img">
                    ▲输入法人手机号码接收到的验证码
                </div>
            </div>
            <div v-if="count == 14">
                <div class="step-box">
                    <div class="step-num">4</div>
                    <div class="step-title">领取</div>
                </div>
                <img class="auth-img" src="@/assets/images/auth11.png" alt="">
                <div class="tips-img">
                    ▲看到领取的5枚印章
                </div>
            </div>
            <div v-if="count == 15">
                <div class="step-box">
                    <div class="step-num">4</div>
                    <div class="step-title">领取</div>
                </div>
                <img class="auth-img" src="@/assets/images/auth12.png" alt="">
                <div class="tips-img">
                    ▲完成印章领取
                </div>
            </div>
            <div v-if="count == 16">
                <div class="step-box">
                    <div class="step-num">5</div>
                    <div class="step-title">添加签章用户及授权</div>
                </div>
                <img class="auth-img" src="@/assets/images/01.jpg" alt="">
                <div class="tips-img">
                    ▲点击进入用户中心
                </div>
            </div>
            <div v-if="count == 17">
                <div class="step-box">
                    <div class="step-num">5</div>
                    <div class="step-title">添加签章用户及授权</div>
                </div>
                <img class="auth-img" src="@/assets/images/02.jpg" alt="">
                <div class="tips-img">
                    ▲添加成员
                </div>
            </div>
            <div v-if="count == 18">
                <div class="step-box">
                    <div class="step-num">5</div>
                    <div class="step-title">添加签章用户及授权</div>
                </div>
                <img class="auth-img" src="@/assets/images/03.jpg" alt="">
                <div class="tips-img">
                    ▲输入成员信息并授权印章签署管理员
                </div>
            </div>
            <div v-if="count == 19">
                <div class="step-box">
                    <div class="step-num">5</div>
                    <div class="step-title">添加签章用户及授权</div>
                </div>
                <img class="auth-img" src="@/assets/images/04.jpg" alt="">
                <div class="tips-img">
                    ▲进入印章服务的授权管理创建新的授权
                </div>
            </div>
            <div v-if="count == 20">
                <div class="step-box">
                    <div class="step-num">5</div>
                    <div class="step-title">添加签章用户及授权</div>
                </div>
                <img class="auth-img" src="@/assets/images/05.jpg" alt="">
                <div class="tips-img">
                    ▲选择授权对象及印章
                </div>
            </div>

            <div class="btn-box">
                <!-- <van-button class="previousPage" @click="goIndex" type="primary">首页</van-button> -->
                <van-button class="previousPage" @click="backStep" type="info" v-if="count != 1">上一页</van-button>
                <van-button class="previousPage" @click="next" type="info">跳过</van-button>
                <van-button class="previousPage" @click="goStep" type="info">下一页</van-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            count: 1
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        godemoIndex() {
            this.$router.push('/demoIndex')
        },
        next() {
            this.$router.push('/index')
        },
        goStep() {
            // this.$router.push('/agent/index')
            if (this.count == 20) {
                this.next()
                return
            }
            this.count++
        },
        backStep() {
            if (this.count == 1) {
                return
            }
            this.count--
        },
        goIndex() {
            this.$router.push('/')
        }
    }
};
</script>
<style scoped>
.auth-img {
    width: 100%;
    margin-bottom: 10px;
}

.title-auth {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.item-auth {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.red {
    color: #ff0000;
}

.tips-img {
    color: rgb(172, 57, 255);
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
}

.str {
    font-weight: bold;
}

.step-box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.step-num {
    font-size: 18px;
    color: rgb(255, 255, 255);
    background-color: rgb(178, 236, 145);
    border-radius: 100%;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    font-weight: bold;
    margin-right: 15px;
}

.step-title {
    padding: 4px 16px;
    font-size: 18px;
    color: rgb(255, 255, 255);
    background-color: rgb(120, 192, 253);
    border-radius: 20px 20px 20px 0px;
    letter-spacing: 1.5px;
}

.btn {
    width: 300px;
    height: 80px;
    display: block;
    margin: auto;
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
}

.btn-box {
    text-align: center;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: space-around;
}

.previousPage {
    /* width: 30%; */
    width: 100px;
    height: 40px;
    border-radius: 10px;
    line-height: 40px;
    font-size: 16px;
    margin-bottom: 30px;
    font-weight: 800;
    color: #ffffff;
}

.yanshi {
    position: absolute;
    top: 5px;
    right: 20px;
    font-size: 16px;
    background-color: #1AAD19;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    color: #ffffff;
}
</style>