import request from "@/network/request"
function get(url, data, header, type, clear = false) {
  return request({
    url: url,
    method: 'GET',
    data: data,
    headers: header,
    type,
    clear

  })
}

function post(url, data, header, type, clear = false) {
  return request({
    url: url,
    method: 'POST',
    data: data,
    headers: header,
    type,
    clear
  })
}
var api = {
  // 获取小程序二维码
  getWxCode_WXEwmSeismic: (type) => post('/getWxCode/WXKZEwm', '', '', type),
  // 用户信息填写获取二维码
  agent_getQrInfo: (type) => post('/agent/getQrInfo', '', '', type),
  // 获取用户扫脸信息
  pass_receiveinfo: data => post(`/receiveinfo/${data}`, '', '', 'agent', true),
  // 查询人脸照片是否上传
  pass_QuakePdf: data => get(`/QuakePdf/${data}`),
  // 获取法人照片
  pdf_list: data => get('/pdf/list', data),
  // 签名上传
  // earthquake_saveSignature: data => post('/earthquake/saveSignature', data),
  // 邮递信息
  // mail_add: data => post('/mail/add', data),
  // 提交表单
  earthquake_addQuark: data => post('/earthquake/addQuark', data),
  // 发送短信
  license_sendKWKZSms: (data, type) => post('/license/sendKWKZSms', data, '', type),
  // 打印记录
  // license_savePrintRecord: data => post('/license/savePrintRecord', data),
  // 查询打印记录列表
  mail_printRecordList: data => post('/mail/printRecordList', data),
  // 表单详情
  // earthquake_getQuakeByUniscid: data => get(`/earthquake/getQuakeByUniscid`, data),
  earthquake_getList: data => get(`/earthquake/getList`, data),
  // 上传照片
  industryFile_upfiles: (el, data) => post("/industryFile/upfiles?type=" + el.value + "&pid=" + el.fpid, data),
  // 获取上传列表
  industryFile_getVantlistByClass: data => get(`/industryFile/getVantlistByClass?pid=${data}`),
  // 删除文件
  industryFile_delfile: data => post(`/industryFile/delfile?id=${data}`),
  // 项目编号查询
  GGProject_findByXmbh: data => post(`/GGProject/findByXmbh/${data}`, '', {}, 'gg'),
  // 工改
  soap_sendGG: (data) => post(`/soap/sendGG/${data}`, '', '', 'codeUrl'),
}
export default api