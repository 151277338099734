import axios from "axios"
import base from './base'
import { Toast } from 'vant'


var instance = axios.create({
    baseURL: base,
    timeout: 65000
});
// var toast;
instance.interceptors.request.use(function (config) {
    // console.log('config', config);
    if (!config.clear) {
        Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: '加载中...',
        })
    }
    // 需要对get请求发送的数据去做处理 
    if (config.method.toLowerCase() == "get") {
        config.params = config.data
    }
    if (config.type == 'codeUrl') {
        // config.baseURL = 'http://112.243.253.8:8280/prod-api/enjoy/pass'
      config.baseURL = "https://zw.zwfwpt.cn/diving-api/enjoy/pass";
        config.timeout=9999999
    }
    if (config.type == 'agent') {
        config.baseURL = 'https://zw.zwfwpt.cn/prod-api/enjoy/pass'
    }
    if (config.type == 'gg') {
        // 工改查询
        // config.baseURL = 'http://112.243.253.8:8081/prod-api/enjoy/pass'
      config.baseURL = "https://zw.zwfwpt.cn/prod-api/enjoy/pass";
    }
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    Toast.fail(error);
    return Promise.reject(error);
});

// 数据响应之后执行的操作
instance.interceptors.response.use(function (response) {
    // if (response.data.code != 200) {
    //     Toast.fail(response.data.msg);
    // }
    Toast.clear()
    // 对响应数据做点什么
    return response.data;
}, function (error) {
    // 对响应错误做点什么
    Toast.fail(error);
    return Promise.reject(error);
});

export default instance