import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from "./network/api.js"
import base from "./network/base.js"
import { pdfUrl } from "./network/base.js"
import Vant from 'vant';
import 'vant/lib/index.css';
import 'lib-flexible'
import fn from "./utils/public.js"
import vueEsign from 'vue-esign'



Vue.use(Vant)
Vue.use(vueEsign)

Vue.config.productionTip = false
Vue.prototype.$rqt = api
Vue.prototype.$fun = fn
Vue.prototype.$base = base
Vue.prototype.$pdfUrl = pdfUrl

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
