import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
// import index from '../views/index.vue'
// 使用规则
import index from '../views/auth.vue'
// 模拟终端
// import zhongduan from '../views/zhongduan.vue'
// import Seismic from "../views/seismic.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'zhongduan',
    component: index
  },
  // {
  //   path: '/',
  //   name: 'zhongduan',
  //   component: zhongduan
  //   // component: Seismic
  // },
  {
    path: '/idx',
    name: 'idx',
    component: () => import('../views/auth.vue')
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  // 填写登记表
  {
    path: '/seismic',
    name: 'seismic',
    component: () => import('../views/seismic.vue')
  },
  // 规则
  {
    path: '/rulesSeismic',
    name: 'rulesSeismic',
    component: () => import('../views/rulesSeismic.vue')
  },
  // 签名
  {
    path: '/signName',
    name: 'signName',
    component: () => import('../views/signNameWeb.vue')
  },
  // 邮递信息
  {
    path: '/mail',
    name: 'mail',
    component: () => import('../views/mail.vue')
  },
  // 查询列表
  {
    path: '/seismicList',
    name: 'seismicList',
    component: () => import('../views/seismicList.vue')
  },
  // 详情
  {
    path: '/seismicDetails',
    name: 'seismicDetails',
    component: () => import('../views/seismicDetails.vue')
  },
  // 查询打印记录
  {
    path: '/printList',
    name: 'printList',
    component: () => import('../views/printList.vue')
  },
  // 查询项目编号
  {
    path: '/check',
    name: 'check',
    component: () => import('../views/check.vue')
  },
  // 文件上传
  {
    path: '/uploadFile',
    name: 'uploadFile',
    component: () => import('../views/uploadFile.vue')
  },
  // 文件上传
  {
    path: '/uploadView',
    name: 'uploadView',
    component: () => import('../views/uploadView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
    // 演示页面首页
  {
    path: '/demoIndex',
    name: 'DemoIndex',
    component: () => import('../views/Demonstration/index.vue')
  },
  {
    path: '/demoRulesSeismic',
    name: 'DemoRulesSeismic',
    component: () => import('../views/Demonstration/rulesSeismic.vue')
  },
  {
    path: '/demoCheck',
    name: 'DemoCheck',
    component: () => import('../views/Demonstration/check.vue')
  },
  {
    path: '/demoSeismic',
    name: 'DemoSeismic',
    component: () => import('../views/Demonstration/seismic.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
